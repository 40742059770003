<template>
    <div class="pt-16 px-2 ">
        <h1 class="text-red text-lg mb-6">
            Create License
        </h1>
        <div class="" >
            <FormTemplate @response="formResponse" button="Create" method="post" action="/license/create" :formdata="formData">
                <SearchSelectInput @item:removed="accountRemoved" @item:selected="accountSelected" :params="accountSearchParams" :multiple="false" label="Account" v-model="item.account" :optiondisplay="accountDisplay"></SearchSelectInput>
                <SearchSelectInput @item:removed="item.product = false" @item:selected="productSelected" :params="productSearchParams" :multiple="false" label="Product" v-model="item.product" :optiondisplay="productDisplay"></SearchSelectInput>
                <SearchSelectInput @item:removed="addonRemoved" @item:selected="addonSelected" :params="addonSearchParams" :multiple="true" label="Addon" v-model="item.addon" :optiondisplay="addonDisplay"></SearchSelectInput>
                <TextInput type="number" min="1" max="100" :required="true" v-model="item.quantity"/>
                <SelectInput v-model="item.status" label="Status" :required="true" :options="statuses"></SelectInput>
            </FormTemplate>
        </div>
    </div>
</template>

<script>
import Create from '../../../mixins/Create'

export default {
    name: 'CreateLicense',
    mixins: [Create],
    data: () => ({
        back: '/licenses',
        item: {
            product: false,
            addon: [],
            account: false,
            quantity: 1,
            status: 'Active'
        },
        productSearchParams: {
            'model': 'Product',
            'fields': ['id','name'],
            'action': '/search/',
        },
        addonSearchParams: {
            'model': 'Product',
            'fields': ['id','name'],
            'action': '/search/',
        },
        accountSearchParams: {
            'model': 'Account',
            'fields': ['id','name'],
            'action': '/search/',
        },
        action: '/license/',
        statuses: [
            {
                label: 'Active',
                value: 'Active',
            },
            {
                label: 'Inactive',
                value: 'Inactive',
            }
        ],
    }),
    computed: {
        formData() {
            return {
                'license':this.item,
            }
        },
    },
    props: [],
    methods: {
        generatePw() {
            this.$set(this.item,'password',Math.random().toString(36).slice(-10))
        },
        productDisplay(item) {
            return `ID: ${item.id} - Name: ${item.name} - Price: ${item.price}`;
        },
        addonDisplay(item) {
            return `ID: ${item.id} - Name: ${item.name} - Price: ${item.price}`;
        },
        accountDisplay(item) {
            return 'ID: ' + item.id + ' Name: ' + item.name;
        },
        accountSelected(evt) {
            this.item.account = evt.item
            this.domainSearchParams.conditions.account.value = evt.item.id
        },
        accountRemoved() {
            this.item.acount = false
            this.domainSearchParams.conditions.account.value = false
        },
        productSelected(evt) {
            this.item.product = evt.item
        },
        addonSelected(evt) {
            this.item.addon.push(evt.item);
        },
        addonRemoved(evt) {
            this.item.addon.splice(evt.index,1);
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
