<template>
    <div class="pt-16 px-2 ">
        <h1 class="text-red text-lg mb-6">
            Edit License
        </h1>
      <strong>Created At:</strong> {{showCreatedAt()}}
      <br>
      <strong>Last Updated:</strong> {{showUpdatedAt()}}
        <div class="" v-if="item">
            <FormTemplate @response="formResponse" button="Save" method="post" action="/license" :formdata="formData">
                <TextInput type="text" label="Key" v-model="item.key" :required="false" ></TextInput>
                <TextInput type="number" label="Activations" v-model="item.activations" :required="false" ></TextInput>
                <SelectInput label="Type" v-model="item.type" :options="[{label:'Subscription',value:'Subscription'},{label:'Manual',value:'Manual'}]" :required="false" ></SelectInput>
                <SelectInput label="Status" v-model="item.status" :options="[{label:'Active',value:'Active'},{label:'Inactive',value:'Inactive'}]" :required="false" ></SelectInput>
                <SearchSelectInput @item:clicked="AgencyClick" @item:removed="accountRemoved" @item:selected="accountSelected" :params="accountSearchParams" :multiple="false" label="Agency" v-model="item.agency" :optiondisplay="accountDisplay" :hideSelected="false"></SearchSelectInput>
                <SearchSelectInput @item:removed="subscriptionRemoved" @item:selected="subscriptionSelected" :params="subscriptionSearchParams" :multiple="false" label="Subscription" v-model="item.subscription" :optiondisplay="subscriptionDisplay" :hideSelected="false"></SearchSelectInput>
                <SearchSelectInput @item:removed="domainRemoved" @item:selected="domainSelected" :params="domainSearchParams" :multiple="true" label="Domains" v-model="item.domains" :optiondisplay="domainDisplay"></SearchSelectInput>
                <SearchSelectInput @item:removed="item.product = false" @item:selected="productSelected" :params="productSearchParams" :multiple="false" label="Product" v-model="item.product" :optiondisplay="productDisplay"></SearchSelectInput>
                <SearchSelectInput @item:removed="addonRemoved" @item:selected="addonSelected" :params="addonSearchParams" :multiple="true" label="Addon" v-model="item.addons" :optiondisplay="addonDisplay"></SearchSelectInput>
            </FormTemplate>
        </div>
    </div>
</template>

<script>
import View from '../../../mixins/View'
export default {
    name: 'License',
    mixins: [View],
    data: () => ({
        subscriptionSearchParams: {
            'model': 'Subscription',
            'fields': ['id','uid','invoice'],
            'action': '/search/',
        },
        accountSearchParams: {
            'model': 'Account',
            'fields': ['id','name','email','phone'],
            'action': '/search/',
        },
        domainSearchParams: {
            'model': 'Domain',
            'fields': ['id','domain'],
            'conditions': {
                'account': {
                    'where': 'account_id',
                    'comparison': '=',
                    'value': false,
                },   
            },
            'action': '/search/',
        },
        parameter: 'license_id',
        action: '/license/',
      productSearchParams: {
        'model': 'Product',
        'fields': ['id','name'],
        'action': '/search/',
      },
      addonSearchParams: {
        'model': 'Product',
        'fields': ['id','name'],
        'action': '/search/',
      },
    }),
    computed: {
        formData() {
            return {
                'license':this.item,
            }
        },
    },
    props: [],
    methods: {
        domainDisplay(item) {
            return 'ID: ' + item.id + ' Domain: ' + item.domain;
        },
        subscriptionDisplay(item) {
            return 'ID: ' + item.id + ' Uid: ' + item.uid + ' Invoice: '+item.invoice;
        },
        subscriptionSelected(evt) {
            this.item.subscription = evt.item
            this.item.subscription_id = evt.item.id
        },
        subscriptionRemoved(evt) {
            this.item.subscription = null
            this.item.subscription_id = null
        },
        accountDisplay(item) {
            return 'ID: ' + item.id + ' Name: ' + item.name;
        },
        accountSelected(evt) {
            this.item.agency = evt.item
            this.item.account_id = evt.item.id
        },
        accountRemoved(evt) {
            this.item.agency = null
            this.item.account_id = null
        },
        AgencyClick(evt) {
            this.$router.push({path: `/agencies/${evt.item.id}`})
        },
        domainSelected(evt) {
            this.item.domains.push(evt.item)
        },
        domainRemoved(evt) {
            let index = this.item.domains.findIndex((domain)=>{return domain.id == evt.item.id})
            if(index >= 0 ) this.item.domains.splice(index,1)
        },
        showCreatedAt(){
          let createdDate = new Date(this.item.created_at);
          return createdDate.toLocaleDateString();
        },
        showUpdatedAt(){
          let createdDate = new Date(this.item.updated_at);
          return createdDate.toLocaleDateString();
        },
        productDisplay(item) {
          return `ID: ${item.id} - Name: ${item.name} - Price: ${item.price}`;
        },
        addonDisplay(item) {
          return `ID: ${item.id} - Name: ${item.name} - Price: ${item.price}`;
        },
        productSelected(evt) {
          this.item.product = evt.item
        },
        addonSelected(evt) {
          this.item.addons.push(evt.item);
        },
        addonRemoved(evt) {
          this.item.addons.splice(evt.index,1);
        },
    },
    watch: {
        item: {
            handler(val) {
                this.domainSearchParams.conditions.account.value = val.account_id
            },
            deep:true,
        },
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
