<template>
<div class="container pt-16 px-2 ">
    <div class="flex">
        <h1 class="text-red text-lg mb-6">
            Licenses
        </h1>
        <LinkTemplate :to="{path:'/licenses/create'}" class="my-6">
            Add New License
        </LinkTemplate>
    </div>
    <div class="">
        <TextInput @input="maybeSearch" type="text" label="Search" v-model="search" :required="false" ></TextInput>
    </div>
    <div v-if="items.length || search != ''"  class="">
        <TableTemplate v-if="loading == false" @item:deleted="ItemDeleted" :candelete="false" canview="/licenses/" :columns="columns" :items="items"></TableTemplate>
        <img v-else class="m-auto my-4" style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading search submission">
    </div>
    <div  v-else  class="">
        <TableTemplate v-if="loading == false" @item:deleted="ItemDeleted" :candelete="false" canview="/licenses/" :columns="columns" :items="pages[page]"></TableTemplate>
        <img v-else class="m-auto my-4" style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading search submission">
        <Pagination @navigate="navigate" :count="count" :limit="limit" :page="page"></Pagination>
    </div>
</div>
</template>

<script>
import List from '../../../mixins/List'
export default {
    name: 'LicenseList',
    mixins: [List],
    data: () => ({
        products: [],
        limit: 10,
        page: 0,
        action: '/licenses',
        searchParams: {
            'model': 'License',
            'fields': ['id','key','product'],
            'relationships': {'product' : {
                key: 'name',
            }}
        },
        columns:[
            {
                id:'id',
                label:'ID',
            },
            {
                id:'key',
                label:'Key',
            },
            {
                id: 'product_id',
                label: 'Product',
                custom_property: 'product_name'
            },
            {
                id:'account_id',
                label:'View Agency',
                action: 'ViewAgency',
                action_type: 'link'
            },
            {
                id:'subscription_id',
                label:'View Subscription',
                action: 'ViewSubscription',
                action_type: 'link'
            },
            {
                id:'status',
                label:'Status',
            },
        ],
    }),
    computed: {
        visible_items() {
            if(this.items.length) return this.items 
            return this.pages[this.page]
        },
    },
    props: [],
    methods: {
        navigate(index) {
            if(index > -1 && index <= Math.floor(this.count/10)) {
                this.page = index
                this.getItems()
            }
        },
        ViewAgency(item) {
            this.$router.push({path:'/agencies/'+item.account_id})
        },
        ViewSubscription(item) {
            console.log(item.subscription_id)
        },
        getProducts() {
            this.$requests.get('/products',
            {
                success: (re) => {
                    this.products = re.data.products
                    this.OnGetItemsCompleted()
                    return true
                },
            })
        },
        OnGetItemsCompleted() {
            if(this.pages[this.page] && this.products.length) {
                for(let i in this.pages[this.page]) {
                    let product = this.products.find((object) => {return object.id == this.pages[this.page][i].product_id})
                    if(product) {
                        this.$set(this.pages[this.page][i],'product_name',product.name)
                    }else {
                        this.$set(this.pages[this.page][i],'product_name','Not Found')
                    }
                }
            }
        },
        OnGetItemsSearched() {
            if(this.items && this.products.length) {
                for(let i in this.items) {
                    let product = this.products.find((object) => {return object.id == this.items[i].product_id})
                    if(product) {
                        this.$set(this.items[i],'product_name',product.name)
                    }else {
                        this.$set(this.items[i],'product_name','Not Found')
                    }
                }
            }
        },
    },
    watch: {
    },
    created() {
        this.getProducts()
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
